import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import EventDetailScreenAdapter from "../../blocks/adapters/src/EventDetailScreenAdapter";

//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const eventDetailScreenAdapter = new EventDetailScreenAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to UTSStadiumExperienceFanEngagement!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'ComponentsPage'}
              onPress={() => navigation.navigate("ComponentsPage")}
            />
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
            <CustomTextItem content={'PhotoLibrary'} onPress={() => navigation.navigate("PhotoLibrary")} />
            <CustomTextItem content={'core'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'utilities'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Followers'} onPress={() => navigation.navigate("Followers")} />
            <CustomTextItem content={'Search'} onPress={() => navigation.navigate("Search")} />
            <CustomTextItem content={'Pushnotifications'} onPress={() => navigation.navigate("Pushnotifications")} />
            <CustomTextItem content={'LanguageSupport'} onPress={() => navigation.navigate("LanguageSupport")} />
            <CustomTextItem content={'LanguageOptions'} onPress={() => navigation.navigate("LanguageOptions")} />
            <CustomTextItem content={'EventRegistration'} onPress={() => navigation.navigate("EventRegistration")} />
            <CustomTextItem content={'Dashboard'} onPress={() => navigation.navigate("Dashboard")} />
            <CustomTextItem content={'admanager'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Catalogue'} onPress={() => navigation.navigate("Catalogue")} />
            <CustomTextItem content={'contentmanagement'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'PerformanceTracker'} onPress={() => navigation.navigate("PerformanceTracker")} />
            <CustomTextItem content={'DeliveryEstimator'} onPress={() => navigation.navigate("DeliveryEstimator")} />
            <CustomTextItem content={'Gamification'} onPress={() => navigation.navigate("Gamification")} />
            <CustomTextItem content={'Hashtags'} onPress={() => navigation.navigate("Hashtags")} />
            <CustomTextItem content={'hashtaggenerator'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'newsfeed'} onPress={() => navigation.navigate('NewsFeed')} />
            <CustomTextItem content={'Categoriessubcategories'} onPress={() => navigation.navigate("Categoriessubcategories")} />
            <CustomTextItem content={'VisualAnalytics'} onPress={() => navigation.navigate("VisualAnalytics")} />
            <CustomTextItem content={'Videos'} onPress={() => navigation.navigate("Videos")} />
            <CustomTextItem content={'Geofence'} onPress={() => navigation.navigate("Geofence")} />
            <CustomTextItem content={'Addresses'} onPress={() => navigation.navigate("Addresses")} />
            <CustomTextItem content={'CarouselDisplay'} onPress={() => navigation.navigate("CarouselDisplay")} />
            <CustomTextItem content={'OrderManagement'} onPress={() => navigation.navigate("OrderManagement")} />
            <CustomTextItem content={'PhoneNumberInput'} onPress={() => navigation.navigate("PhoneNumberInput")} />
            <CustomTextItem content={'CountryCodeSelector'} onPress={() => navigation.navigate("CountryCodeSelector")} />
            <CustomTextItem content={'OTPInputAuth'} onPress={() => navigation.navigate("OTPInputAuth")} />
            <CustomTextItem content={'EmailAccountRegistration'} onPress={() => navigation.navigate("EmailAccountRegistration")} />
            <CustomTextItem content={'SocialMediaAccountRegistrationScreen'} onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
            <CustomTextItem content={'social-media-account'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'mentionstagging'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Location'} onPress={() => navigation.navigate("Location")} />
            <CustomTextItem content={'Reviews'} onPress={() => navigation.navigate("Reviews")} />
            <CustomTextItem content={'Notifications'} onPress={() => navigation.navigate("Notifications")} />
            <CustomTextItem content={'Scheduling'} onPress={() => navigation.navigate("Scheduling")} />
            <CustomTextItem content={'Emojis'} onPress={() => navigation.navigate("Emojis")} />
            <CustomTextItem content={'ShoppingCartOrders'} onPress={() => navigation.navigate("ShoppingCartOrders")} />
            <CustomTextItem content={'Contactus'} onPress={() => navigation.navigate("Contactus")} />
            <CustomTextItem content={'Maps'} onPress={() => navigation.navigate("Maps")} />
            <CustomTextItem content={'comments'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'PostCreation'} onPress={() => navigation.navigate("PostCreation")} />
            <CustomTextItem content={'events'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Favourites'} onPress={() => navigation.navigate("Favourites")} />
            <CustomTextItem content={'StripePayments'} onPress={() => navigation.navigate("StripePayments")} />
            <CustomTextItem content={'QrCodes'} onPress={() => navigation.navigate("QrCodes")} />
            <CustomTextItem content={'InventoryManagement'} onPress={() => navigation.navigate("InventoryManagement")} />
            <CustomTextItem content={'Leaderboard'} onPress={() => navigation.navigate("Leaderboard")} />
            <CustomTextItem content={'SocialMediaAccountLoginScreen'} onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
            <CustomTextItem content={'LoyaltySystem'} onPress={() => navigation.navigate("LoyaltySystem")} />
            <CustomTextItem content={'Invitefriends'} onPress={() => navigation.navigate("Invitefriends")} />
            <CustomTextItem content={'UserProfileBasicBlock'} onPress={() => navigation.navigate("UserProfileBasicBlock")} />
            <CustomTextItem content={'EducationalUserProfile'} onPress={() => navigation.navigate("EducationalUserProfile")} />
            <CustomTextItem content={'Splashscreen'} onPress={() => navigation.navigate("Splashscreen")} />
            <CustomTextItem content={'NearbyFriends'} onPress={() => navigation.navigate("NearbyFriends")} />
            <CustomTextItem content={'DownloadOptions'} onPress={() => navigation.navigate("DownloadOptions")} />
            <CustomTextItem content={'Polling'} onPress={() => navigation.navigate("Polling")} />
            <CustomTextItem content={'Trending'} onPress={() => navigation.navigate("Trending")} />
            <CustomTextItem content={'Reservations'} onPress={() => navigation.navigate("Reservations")} />
            <CustomTextItem content={'EmailAccountLoginBlock'} onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
            <CustomTextItem content={'ForgotPassword'} onPress={() => navigation.navigate("ForgotPassword")} />
            <CustomTextItem content={'Customisableusersubscriptions'} onPress={() => navigation.navigate("Customisableusersubscriptions")} />
            <CustomTextItem content={'AutomaticRenewals'} onPress={() => navigation.navigate("AutomaticRenewals")} />
            <CustomTextItem content={'AuctionBidding'} onPress={() => navigation.navigate("AuctionBidding")} />
            <CustomTextItem content={'NavigationMenu'} onPress={() => navigation.navigate("NavigationMenu")} />
            <CustomTextItem content={'TermsConditions'} onPress={() => navigation.navigate("TermsConditions")} />
            <CustomTextItem content={'deeplinking'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'ActivityFeed'} onPress={() => navigation.navigate("ActivityFeed")} />
            <CustomTextItem content={'Filteritems'} onPress={() => navigation.navigate("Filteritems")} />
            <CustomTextItem content={'VideoTrimmer'} onPress={() => navigation.navigate("VideoTrimmer")} />
            <CustomTextItem content={'LandingPage'} onPress={() => navigation.navigate("LandingPage")} />
            <CustomTextItem content={'PrivacySettings'} onPress={() => navigation.navigate("PrivacySettings")} />
            <CustomTextItem content={'Share'} onPress={() => navigation.navigate("Share")} />
            <CustomTextItem content={'Chat'} onPress={() => navigation.navigate("Chat")} />
            <CustomTextItem content={'CameraAccess'} onPress={() => navigation.navigate("CameraAccess")} />
            <CustomTextItem content={'promocodes'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Avatar'} onPress={() => navigation.navigate("Avatar")} />
            <CustomTextItem content={'productdescription'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Fanwall2'} onPress={() => navigation.navigate("Fanwall2")} />
            <CustomTextItem content={'Friendlist2'} onPress={() => navigation.navigate("Friendlist2")} />
            <CustomTextItem content={'Appupdates'} onPress={() => navigation.navigate("Appupdates")} />
            <CustomTextItem content={'Adminconsole2'} onPress={() => navigation.navigate("Adminconsole2")} />
            <CustomTextItem content={'Communityforum4'} onPress={() => navigation.navigate("Communityforum4")} />
            <CustomTextItem content={'Contentmoderation'} onPress={() => navigation.navigate("Contentmoderation")} />
            <CustomTextItem content={'Videolibrary2'} onPress={() => navigation.navigate("Videolibrary2")} />
            <CustomTextItem content={'Videomanagement'} onPress={() => navigation.navigate("Videomanagement")} />
            <CustomTextItem content={'Hdvideoformats'} onPress={() => navigation.navigate("Hdvideoformats")} />
            <CustomTextItem content={'Contentdeliverynetworkcdnonserver'} onPress={() => navigation.navigate("Contentdeliverynetworkcdnonserver")} />
            <CustomTextItem content={'Itemavailability'} onPress={() => navigation.navigate("Itemavailability")} />
            <CustomTextItem content={'Shippingchargecalculator'} onPress={() => navigation.navigate("Shippingchargecalculator")} />
            <CustomTextItem content={'Settings2'} onPress={() => navigation.navigate("Settings2")} />
            <CustomTextItem content={'Likeapost2'} onPress={() => navigation.navigate("Likeapost2")} />
            <CustomTextItem content={'Dashboardguests'} onPress={() => navigation.navigate("Dashboardguests")} />
            <CustomTextItem content={'Uploadmedia3'} onPress={() => navigation.navigate("Uploadmedia3")} />
            <CustomTextItem content={'Paidcontent'} onPress={() => navigation.navigate("Paidcontent")} />
            <CustomTextItem content={'Dynamiccontent2'} onPress={() => navigation.navigate("Dynamiccontent2")} />
            <CustomTextItem content={'Playlist2'} onPress={() => navigation.navigate("Playlist2")} />
            <CustomTextItem content={'Locationbasedalerts2'} onPress={() => navigation.navigate("Locationbasedalerts2")} />
            <CustomTextItem content={'Mergestagingtolive'} onPress={() => navigation.navigate("Mergestagingtolive")} />
            <CustomTextItem content={'Subscriptionbilling2'} onPress={() => navigation.navigate("Subscriptionbilling2")} />
            <CustomTextItem content={'Emailnotifications2'} onPress={() => navigation.navigate("Emailnotifications2")} />
            <CustomTextItem content={'Inapppurchasing'} onPress={() => navigation.navigate("Inapppurchasing")} />
            <CustomTextItem content={'Refundmanagement2'} onPress={() => navigation.navigate("Refundmanagement2")} />
            <CustomTextItem content={'Paymentadmin2'} onPress={() => navigation.navigate("Paymentadmin2")} />
            <CustomTextItem content={'Cftenniscoins12'} onPress={() => navigation.navigate("Cftenniscoins12")} />
            <CustomTextItem content={'Cftenniscoins23'} onPress={() => navigation.navigate("Cftenniscoins23")} />
            <CustomTextItem content={'Rolesandpermissions'} onPress={() => navigation.navigate("Rolesandpermissions")} />
            <CustomTextItem content={'Freshdeskintegration'} onPress={() => navigation.navigate("Freshdeskintegration")} />
            <CustomTextItem content={'Cfspamengine2'} onPress={() => navigation.navigate("Cfspamengine2")} />

            <CustomTextItem content={'Videolibrary'} onPress={() => navigation.navigate("Videolibrary")} />

            <CustomTextItem content={'Paidcontent2'} onPress={() => navigation.navigate("Paidcontent2")} />

            <CustomTextItem content={'communityforum'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'refundmanagement'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />

            <CustomTextItem content={'locationbasedalerts'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />

            <CustomTextItem content={'fanwall'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />

            <CustomTextItem content={'Mergestagingtolive2'} onPress={() => navigation.navigate("Mergestagingtolive2")} />
            <CustomTextItem content={'Rolesandpermissions2'} onPress={() => navigation.navigate("Rolesandpermissions2")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;