// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Reservations from "../../blocks/reservations/src/Reservations";
import Fanwall2 from "../../blocks/fanwall2/src/Fanwall2";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Dynamiccontent2 from "../../blocks/dynamiccontent2/src/Dynamiccontent2";
import Cftenniscoins23 from "../../blocks/cftenniscoins23/src/Cftenniscoins23";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Cfspamengine2 from "../../blocks/cfspamengine2/src/Cfspamengine2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import NearbyFriends from "../../blocks/nearbyfriends/src/NearbyFriends";
import AdManager from "../../blocks/admanager/src/AdManager";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Mergestagingtolive from "../../blocks/mergestagingtolive/src/Mergestagingtolive";
// import Itemavailability from "../../blocks/itemavailability/src/Itemavailability";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
// import Freshdeskintegration from "../../blocks/freshdeskintegration/src/Freshdeskintegration";
import Notifications from "../../blocks/notifications/src/Notifications";
// import Location from "../../blocks/location/src/Location";
import Leaderboard from "../../blocks/leaderboard/src/Leaderboard";
import Shippingchargecalculator from "../../blocks/shippingchargecalculator/src/Shippingchargecalculator";
import Friendlist2 from "../../blocks/friendlist2/src/Friendlist2";
import Geofence from "../../blocks/geofence/src/Geofence";
import VideoTrimmer from "../../blocks/videotrimmer/src/VideoTrimmer";
import Locationbasedalerts2 from "../../blocks/locationbasedalerts2/src/Locationbasedalerts2";
import Cftenniscoins12 from "../../blocks/cftenniscoins12/src/Cftenniscoins12";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Inapppurchasing from "../../blocks/inapppurchasing/src/Inapppurchasing";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
// import Dashboardguests from "../../blocks/dashboardguests/src/Dashboardguests";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Avatar from "../../blocks/avatar/src/Avatar";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
// import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import EventRegistration from "../../blocks/eventregistration/src/EventRegistration";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import PrivacySettings from "../../blocks/privacysettings/src/PrivacySettings";
import Emojis from "../../blocks/emojis/src/Emojis";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import Appupdates from "../../blocks/appupdates/src/Appupdates";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import Followers from "../../blocks/followers/src/Followers";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import CreateComment from "../../blocks/comments/src/CreateComment";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import DeliveryEstimator from "../../blocks/deliveryestimator/src/DeliveryEstimator";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import Communityforum4 from "../../blocks/communityforum4/src/Communityforum4";
import Search from "../../blocks/search/src/Search";
import Videolibrary2 from "../../blocks/videolibrary2/src/Videolibrary2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Videos from "../../blocks/videos/src/Videos";
import Hdvideoformats from "../../blocks/hdvideoformats/src/Hdvideoformats";
// import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
// import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
// import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Videomanagement from "../../blocks/videomanagement/src/Videomanagement";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
// import Contentmoderation from "../../blocks/contentmoderation/src/Contentmoderation";
import Refundmanagement2 from "../../blocks/refundmanagement2/src/Refundmanagement2";
import Polling from "../../blocks/polling/src/Polling";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
// import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
// import PerformanceTrackerCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerCoursesScreen";
// import PerformanceTrackerTestsForCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestsForCourseScreen";
// import PerformanceTrackerStudentsOnCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentsOnCourseScreen";
// import PerformanceTrackerTestScoresForStudentScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestScoresForStudentScreen";
// import PerformanceTrackerBasicUserCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserCoursesScreen";
// import PerformanceTrackerBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserAllCoursesScreen";
// import PerformanceTrackerStudentRankingScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentRankingScreen";
// import PerformanceTrackerStudentInfoScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentInfoScreen";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Contentdeliverynetworkcdnonserver from "../../blocks/contentdeliverynetworkcdnonserver/src/Contentdeliverynetworkcdnonserver";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import Paidcontent from "../../blocks/paidcontent/src/Paidcontent";
import Trending from "../../blocks/trending/src/Trending";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import Maps from "../../blocks/maps/src/Maps";
import Playlist2 from "../../blocks/playlist2/src/Playlist2";
import Paidcontent2 from "../../blocks/paidcontent2/src/Paidcontent2";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Mergestagingtolive2 from "../../blocks/mergestagingtolive2/src/Mergestagingtolive2";









const routeMap = {
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Mergestagingtolive2:{
 component:Mergestagingtolive2,
path:"/Mergestagingtolive2"},



CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},

Paidcontent2:{
 component:Paidcontent2,
path:"/Paidcontent2"},

// Videolibrary:{
//  component:Videolibrary,
// path:"/Videolibrary"},

Reservations:{
 component:Reservations,
path:"/Reservations"},
Fanwall2:{
 component:Fanwall2,
path:"/Fanwall2"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
// Splashscreen:{
//  component:Splashscreen,
// path:"/Splashscreen"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Dynamiccontent2:{
 component:Dynamiccontent2,
path:"/Dynamiccontent2"},
Cftenniscoins23:{
 component:Cftenniscoins23,
path:"/Cftenniscoins23"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
Cfspamengine2:{
 component:Cfspamengine2,
path:"/Cfspamengine2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
NearbyFriends:{
 component:NearbyFriends,
path:"/NearbyFriends"},
AdManager:{
 component:AdManager,
path:"/AdManager"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Mergestagingtolive:{
 component:Mergestagingtolive,
path:"/Mergestagingtolive"},
// Itemavailability:{
//  component:Itemavailability,
// path:"/Itemavailability"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
// Freshdeskintegration:{
//  component:Freshdeskintegration,
// path:"/Freshdeskintegration"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Location:{
 component:Location,
path:"/Location"},
Leaderboard:{
 component:Leaderboard,
path:"/Leaderboard"},
Shippingchargecalculator:{
 component:Shippingchargecalculator,
path:"/Shippingchargecalculator"},
Friendlist2:{
 component:Friendlist2,
path:"/Friendlist2"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
VideoTrimmer:{
 component:VideoTrimmer,
path:"/VideoTrimmer"},
Locationbasedalerts2:{
 component:Locationbasedalerts2,
path:"/Locationbasedalerts2"},
Cftenniscoins12:{
 component:Cftenniscoins12,
path:"/Cftenniscoins12"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Inapppurchasing:{
 component:Inapppurchasing,
path:"/Inapppurchasing"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
// Dashboardguests:{
//  component:Dashboardguests,
// path:"/Dashboardguests"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Avatar:{
 component:Avatar,
path:"/Avatar"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
// UserProfileBasicBlock:{
//  component:UserProfileBasicBlock,
// path:"/UserProfileBasicBlock"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
PrivacySettings:{
 component:PrivacySettings,
path:"/PrivacySettings"},
Emojis:{
 component:Emojis,
path:"/Emojis"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Share:{
 component:Share,
path:"/Share"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Appupdates:{
 component:Appupdates,
path:"/Appupdates"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
Followers:{
 component:Followers,
path:"/Followers"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
AuctionBidding:{
 component:AuctionBidding,
path:"/AuctionBidding"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
DeliveryEstimator:{
 component:DeliveryEstimator,
path:"/DeliveryEstimator"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
Communityforum4:{
 component:Communityforum4,
path:"/Communityforum4"},
Search:{
 component:Search,
path:"/Search"},
Videolibrary2:{
 component:Videolibrary2,
path:"/Videolibrary2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Videos:{
 component:Videos,
path:"/Videos"},
Hdvideoformats:{
 component:Hdvideoformats,
path:"/Hdvideoformats"},
// ForgotPassword:{
//  component:ForgotPassword,
// path:"/ForgotPassword"},
// ForgotPasswordOTP:{
//  component:ForgotPasswordOTP,
// path:"/ForgotPasswordOTP"},
// NewPassword:{
//  component:NewPassword,
// path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Videomanagement:{
 component:Videomanagement,
path:"/Videomanagement"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
// Contentmoderation:{
//  component:Contentmoderation,
// path:"/Contentmoderation"},
Refundmanagement2:{
 component:Refundmanagement2,
path:"/Refundmanagement2"},
Polling:{
 component:Polling,
path:"/Polling"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
// EmailAccountRegistration:{
//  component:EmailAccountRegistration,
// path:"/EmailAccountRegistration"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
// PerformanceTrackerCoursesScreen:{
//  component:PerformanceTrackerCoursesScreen,
// path:"/PerformanceTrackerCoursesScreen"},
// PerformanceTrackerTestsForCourseScreen:{
//  component:PerformanceTrackerTestsForCourseScreen,
// path:"/PerformanceTrackerTestsForCourseScreen"},
// PerformanceTrackerStudentsOnCourseScreen:{
//  component:PerformanceTrackerStudentsOnCourseScreen,
// path:"/PerformanceTrackerStudentsOnCourseScreen"},
// PerformanceTrackerTestScoresForStudentScreen:{
//  component:PerformanceTrackerTestScoresForStudentScreen,
// path:"/PerformanceTrackerTestScoresForStudentScreen"},
// PerformanceTrackerBasicUserCoursesScreen:{
//  component:PerformanceTrackerBasicUserCoursesScreen,
// path:"/PerformanceTrackerBasicUserCoursesScreen"},
// PerformanceTrackerBasicUserAllCoursesScreen:{
//  component:PerformanceTrackerBasicUserAllCoursesScreen,
// path:"/PerformanceTrackerBasicUserAllCoursesScreen"},
// PerformanceTrackerStudentRankingScreen:{
//  component:PerformanceTrackerStudentRankingScreen,
// path:"/PerformanceTrackerStudentRankingScreen"},
// PerformanceTrackerStudentInfoScreen:{
//  component:PerformanceTrackerStudentInfoScreen,
// path:"/PerformanceTrackerStudentInfoScreen"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Contentdeliverynetworkcdnonserver:{
 component:Contentdeliverynetworkcdnonserver,
path:"/Contentdeliverynetworkcdnonserver"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Paidcontent:{
 component:Paidcontent,
path:"/Paidcontent"},
Trending:{
 component:Trending,
path:"/Trending"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
Maps:{
 component:Maps,
path:"/Maps"},
Playlist2:{
 component:Playlist2,
path:"/Playlist2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;