import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, trimWhitespaceAndNewlines } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  commentData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  postId: any;
  reportVisible: boolean;
  reportPopupVisible: boolean;
  accountId: any,
  commentId: string;
  commentReportedToastMessage: string;
  reportToolTipVisible: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  apiCommentItemCallId: string = "";
  commentApiCallId: string = "";
  createCommentId: string = "";
  likeCommentId: string = "";
  // Customizable Area Start
  postLikeDislikeApiCallId: string = "";
  createCommentApiCallId: string = "";
  apiDisabled: boolean = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      commentData: [],
      errorMsg: "",
      token: "",
      loading: false,
      comment: "",
      commentsArray: [1, 2, 23, 4,],
      replyCommentId: null,
      postId: "",
      reportVisible: false,
      reportPopupVisible: false,
      accountId: "",
      commentId: "",
      commentReportedToastMessage: "",
      reportToolTipVisible: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    } else {
      this.getToken();
    }
    // Customizable Area Start
    const postId = this?.props?.navigation?.state?.params?.postId;
    const accountId = await getStorageData("ACCOUNT_ID")

    this.setState({ postId: postId, accountId: accountId })
    this.getCommentData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.apiDisabled = false;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.commentApiCallId && responseJson && !responseJson.errors) {
        this.setState({ commentData: responseJson?.data?.attributes?.comments?.data });
      }

      if (apiRequestCallId === this.createCommentApiCallId && responseJson && !responseJson.errors) {
        this.getCommentData();
      }

      if (apiRequestCallId === this.postLikeDislikeApiCallId && responseJson && !responseJson.errors) {
        console.log("postLikeApiCallId===========>", JSON.stringify(responseJson));
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  likeOnComment = (item: any, status: boolean) => {
    this.apiDisabled = true;
    if (status === false) {
      this.handleDisLikeOnPost(item.id);
    } else if (status === true) {
      this.handleLikeOnPost(item.id);
    }
  };
  handleReport = (userId: number) => {
    if (userId === Number(this.state.accountId)) {
      return false
    } else {
      return true
    }

  }
  getCommentData = async () => {

    const token = await getStorageData('TOKEN');

    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint + this.state.postId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  createComment = async () => {
    const comment = trimWhitespaceAndNewlines(this.state.comment)

    if (comment.length === 0) {
      return false;
    }

    const token = await getStorageData('TOKEN');

    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const formData = new FormData
    formData.append("id", this.state.postId);
    formData.append("comment", comment);
    formData.append("type", "post");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ comment: "" });
  };

  handleLikeOnPost = async (commentId: any) => {
    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append("id", commentId);
    formData.append("type", "comment");

    this.postLikeDislikeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postLikeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDisLikeOnPost = async (commentId: any) => {

    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append("id", commentId);
    formData.append("type", "comment");

    this.postLikeDislikeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDisLikeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }


  isCommentLengthValid() {
    return this.state.comment.trimEnd().length < 0;
  }
  onComment = (values: any) => {
    this.setState({ comment: values })
  }

  // Customizable Area End
}
